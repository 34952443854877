<template>
  <div class="submit--block">
    <button type="submit" class="btn">{{ text }}</button>
  </div>
</template>


<script>
/**
* Display submit block inside white .form-block 
*   this style is blue button, width 100%
*
* This component can assign button text by 'props'
*
*/

export default {
  props: {
    text: {
      type: String,
      default: 'บันทึกข้อมูล'
    }
  }
}
</script>


<style scoped>

.submit--block {
  margin-top: 15px;
}
.submit--block button {
  width: 100%;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
}
.submit--block button[type=submit] {
  background: #17a;
}
</style>
<template>
  <div class="date-input">
    <datepicker 
      v-model="inputValue" 
      :format="dateFormatter"
      @selected="selectedHandler" 
      class="datepicker-calenter-style"
    >
    </datepicker>

    <i class="fa fa-calendar fa-lg"></i>
  </div>
</template>

<script>
/**
 * DateInput
 * 
 * @description
 *    Input for select date with calendar
 *    extend from 'datepicker' module
 * 
 * 
 * @method: changeDataHandler()
 *    emit data to parent v-model 
 * 
 * @method: selectedHandler()
 *    use for hendle selected event from Datepicker component
 *    and pass value to parent v-model by changeDataHandler() function
 * 
 * @method: dateFormatter()
 *    Comvert date to real date format
 * 
 */

// load library and variables
import moment from 'moment';

// load Components and Module
import Datepicker from 'vuejs-datepicker';

export default {
  props: ['value'],
  data() {
    return {
      inputValue: 0,
      dateFormat: 'YYYY-MM-DD',
    }
  },

  /**
   * Components
   */
  components: {
    Datepicker
  },

  /**
   * pass parent v-model value to component data
   */
  created() {
    this.inputValue = this.value; //.toFixed(2)
  },

  /**
   * All Methods
   */
  methods: {
    /**
     * emit value to parent v-model
     */
    changeDataHandler() {
      this.$emit('input', this.inputValue);
    },

    /**
     * Handle when Datepicker components selected
     */
    selectedHandler(date) {
      this.$nextTick(() => {
        this.inputValue = this.dateFormatter(date);
        this.changeDataHandler();
      });
    },

    /**
     * Comvert date to real date format
     */
    dateFormatter(date) {
      return moment(date).format( this.dateFormat );
    },
  }
}
</script>

<style>
.date-input {
  position: relative;
}
.date-input > i {
  position: absolute;
  top: 50%;
  right: 13px;
  transform: translateY(-50%);
  color: #aaa;
}
</style>
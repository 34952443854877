<template>
  <div class="small-title">
    <h3 
      :class="className"
    >
      {{ title }}

      <span 
        v-if="editLink !== ''"
        class="edit-link"
      >
        <router-link
          :to="editLink"
        >
          แก้ไข
          <i class="fa fa-pencil"></i>
        </router-link>
      </span>
    </h3>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type:String,
      default: ''
    },
    className: {
      type:String,
      default: ''
    },
    editLink: {
      type:String,
      default: ''
    },
  },
}
</script>

<style scoped>
.small-title {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
}
.bottom-line {
  font-size: 20px;
  color: #630;
}
.bottom-line::after {
  content: '';
  display: block;
  margin: 3px 0 10px 0;
  width: 35px;
  height: 2px;
  background: #fc6;
}
.mt-20 {
  margin-top: 20px;
}
.mt-30 {
  margin-top: 30px;
}

.edit-link {
  font-size: 14px;
  font-weight: 400;
}
.edit-link::before {
  content: '-';
  display: inline-block;
  margin: 0 5px;
}
.edit-link a {
  color: #37A21D;
}

</style>
<template>
  <div 
    class="
      duck-form 
      add--project--form
    "
  >
    <form
      @submit.prevent="saveForm"
      autocomplete="off"
    >
      <fieldset 
        class="
          form-block
          add--project--block
        "
      >
        <div 
          class="form-container"
        >
          <SmallTitle 
            title="ข้อมูลระบบ"
            class="bottom-line"
          />
          <div 
            class="input"
            :class="{ error: $v.form.code.$error }"
          >
            <Label 
              text="รหัสโครงการ" 
              :required=true
            />
            <input 
              type="text"
              v-model="form.code"
            >
          </div>
          <!-- end: project code -->

          <div 
            class="input"
          >
            <Label 
              text="จำนวนผู้เสนอราคาที่เปิดรับ" 
              :required=true
            />
            <select v-model="form.auction_limit">
              <option v-for="d in 10" :key="d">{{ d }}</option>
            </select>
          </div>
          <!-- end: auction limit -->
          
          <div 
            class="input"
            v-if="isApproved()"
          >
            <Label 
              text="วันสิ้นสุดการประมูลโครงการ" 
              :required=true
            />
            <DateInput
              v-model="form.auction_end"
            />
          </div>
          <!-- end: auction end date -->
          

          <ProjectSubmitBlock text="บันทึกข้อมูล" />
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>
// load library and variables
import {apiRequest} from '@/utils/axios/axiosInstance.js';
const {required} = require('vuelidate/lib/validators') //, requiredIf

// Load components
import Label                from '@/components/common/form/Label.vue';
import SmallTitle           from '@/components/common/SmallTitle.vue';
import DateInput            from '@/components/common/form/input/DateInput.vue';
import ProjectSubmitBlock   from '@/components/project/form/button/ProjectSubmitBlock.vue';

// Load CSS
import '@/assets/css/project/project-form.css';

export default {
  data() {
    return {
      form: {
        project_id: '',
        code: '',
      },
      project: {}
    }
  },

  /**
   * Validate form data before save to api
   */
  validations: {
    form: {
      code: {required},
      // auction_end: requiredIf( () => this.isApproved() ),
    }
  },

  /**
   * Registration components
   */
  components: {
    Label,
    SmallTitle,
    DateInput,
    // ProjectTypeDropdown,
    // BuildingTypeDropdown,
    // BudgetInput,
    // TimeframeDropdown,
    // DateInput,
    ProjectSubmitBlock
  },

  /**
   * Initial this component when created
   */
  created() {
    this.form.project_id = this.$route.params.id;
    
    // set page title
    this.$store.commit('setPageTitle', 'Edit Project');
    this.$store.commit('setHeadTitle', 'Edit Project : Code');

    // get project data
    this.setForm();
  },

  /**
   * Methods
   */
  methods: {
    /**
     * Get project data
     */
    setForm() {
      this.$store.commit('setLoaderShow', true);

      apiRequest
        .get('/project/' + this.form.project_id)
        .then( (res) => {
          if (res.data.status != 200) {
            alert('การโหลดข้อมูลมีปัญหา กรุณาลองรีเฟรชหน้านี้ใหม่อีกครั้งหรือกด Back เพื่อกลับไปหน้าเดิมของท่าน !!!')
          }else{
            this.project = res.data.result;
            this.form.code = this.project.code;
            this.form.auction_limit = this.project.auction_limit;
            if(this.isApproved()) {
              this.form.auction_end = this.project.auction_end;
            }
            this.$store.commit('setLoaderShow', false);
          }
        });
    },

    /**
     * Save value to database via API
     */
    saveForm() {
      this.$v.$touch()
      
      // // if not validate error
      // // update data to database by API
      if (!this.$v.$invalid) {
      //   // show main loader
        this.$store.commit('setLoaderShow', true);
        
        apiRequest
          .patch(
            '/project/',
            this.form
          )
          .then( (res) => {
            if (res.data.status == 200) {
              this.$router.push('/project/view/' + this.form.project_id);
            } else {
              alert('เกิดข้อผิดพลาดในการบันทึกข้อมูล กรุณารีเฟรชหน้านี้และลองใหม่อีกครั้ง !!!');
            }
          });
      }
    },

    /**
     * Detect current project is approve or not
     */
    isApproved() {
      return this.project.approve == 9;
    }
  }
}
</script>